import { gql } from '@urql/core';
import { useMutation } from 'urql';

import { VendorUpdateOrderMutationInput, VendorUpdateOrderMutationPayload } from 'store/types';

const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: VendorUpdateOrderMutationInput!) {
    orderUpdate(input: $input) {
      id
      created
      status
      user
      serviceType
      menu
      paymentMode
      orderReservation
      errors {
        field
        messages
      }
    }
  }
`;

function useUpdateOrder() {
  return useMutation<
    { orderUpdate: VendorUpdateOrderMutationPayload },
    { input: VendorUpdateOrderMutationInput }
  >(UPDATE_ORDER);
}

export default useUpdateOrder;
