import { devtoolsExchange } from '@urql/devtools';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import Cookies from 'js-cookie';
import { cacheExchange, createClient, dedupExchange } from 'urql';

import urlResolvers from './urlResolvers';

export const clientUrl = process.env.NEXT_PUBLIC_GQL_CLIENT_URL as string;

const client = createClient({
  url: clientUrl,
  requestPolicy: 'cache-and-network',
  fetchOptions: () => {
    const token = Cookies.get('token');

    return {
      headers: { Authorization: token ? `JWT ${token}` : '' },
    };
  },
  exchanges: [devtoolsExchange, dedupExchange, cacheExchange, multipartFetchExchange],
});

export default client;
