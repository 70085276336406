import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import usePrevious from 'lib/hooks/usePrevious';
import { FormattedOrder, OrderStatus } from 'store/location/orders/types';
import useOrdersQuery from 'store/location/orders/useOrders';
import useUpdateOrder from 'store/location/orders/useUpdateOrder';
import useLocation from 'store/location/useLocation';
import { OrderTypeConnection as OrderConnection } from 'store/types';

import { ordersFormatter } from '../../shared/orderFormatter';
import getOrderFields from './getOrderInput';

export type OnStatusChange = (
  selectedItems: string[],
  status: OrderStatus,
) => {
  [x: string]: FormattedOrder;
};

const useRealtimeOrders = (orders: FormattedOrder[]) => {
  const [ordersDict, setOrders] = useState(orderOrganize(orders));
  const prevOrders = usePrevious(orders);
  const [ordersLoading, setOrdersLoading] = useState<string[]>([]);
  const [ordersError, setOrdersError] = useState<string[]>([]);
  const [status, updateOrder] = useUpdateOrder();

  useEffect(() => {
    if (!isEqual(orders, prevOrders)) {
      setOrders(orderOrganize(orders));
    }
  }, [orders, prevOrders]);

  const parallelUpdating = async (
    selectedItems: string[],
    status: OrderStatus,
    ordersDict: {
      [key: string]: FormattedOrder;
    },
  ) => {
    let newOrdersLoading = [...selectedItems];

    for (const selectedItem of selectedItems) {
      try {
        const result = await updateOrder({
          input: getOrderFields(ordersDict[selectedItem].rawOrder, { status }),
        });

        if (result.error || result.data?.orderUpdate.errors) {
          throw new Error('Something went wrong');
        }
        setOrdersError(ordersError.filter(id => id !== selectedItem));
      } catch (error: any) {
        console.error(error.message);
        toast.error(error.message);
        setOrdersError([...ordersError, selectedItem]);
        setOrders(ordersDict);
      }

      newOrdersLoading = newOrdersLoading.filter(id => id !== selectedItem);
      setOrdersLoading(newOrdersLoading);
    }
  };

  const onOrderStatusChange: OnStatusChange = (selectedItems, status) => {
    const newOrdersDict = {
      ...ordersDict,
    };

    for (const orderId of selectedItems) {
      newOrdersDict[orderId] = {
        ...ordersDict[orderId],
        status,
      };
    }

    setOrdersLoading([...ordersLoading, ...selectedItems]);

    parallelUpdating(selectedItems, status, ordersDict);

    setOrders(newOrdersDict);
    return newOrdersDict;
  };

  const updateLocalOrder = (order: FormattedOrder) => {
    setOrders(currentOrders => ({
      ...currentOrders,
      [order.id]: order,
    }));
  };

  return {
    ordersDict,
    ordersLoading,
    ordersError,
    onOrderStatusChange,
    updateLocalOrder,
  };
};

const orderOrganize = (data: FormattedOrder[]) =>
  data.reduce(
    (acc, cur) => ({
      ...acc,
      [`order-${cur.id}`]: cur,
    }),
    {} as { [key: string]: FormattedOrder },
  );

const OrdersContext = createContext<
  ReturnType<typeof useRealtimeOrders> & {
    refetch: Function;
    loading: boolean;
    paginationInfo: {
      totalPages: number;
    };
    error: any;
    formattedOrders: FormattedOrder[];
    data:
      | {
          storeOrders: OrderConnection;
        }
      | undefined;
  }
>({
  paginationInfo: {
    totalPages: 0,
  },
  error: null,
  loading: true,
  ordersDict: {},
  ordersError: [],
  onOrderStatusChange: (() => {}) as any,
  ordersLoading: [],
  formattedOrders: [],
  refetch: () => {},
  updateLocalOrder: () => {},
  data: undefined,
});

function useOrders() {
  return useContext(OrdersContext);
}

const perPage = 100;

function OrdersProvider({
  children,
  useQuery,
}: PropsWithChildren<{ useQuery?: typeof useOrdersQuery }>) {
  const router = useRouter();

  const page = router.query.page;
  let paginationParams: { [key: string]: any } = {};

  if (page && !Number.isNaN(Number(page))) {
    paginationParams = { offset: Math.max(0, Number(page) - 1) * perPage };
  }

  const locationId = router.query.id as string;
  const [location] = useLocation(locationId);
  const [{ data, fetching: loading, error }, refetch] = (useQuery ?? useOrdersQuery)(
    { location_Id: Number(locationId), ...paginationParams, first: perPage },
    !location.data,
  );

  const totalItems = data?.storeOrders?.totalCount ?? 0;

  const formattedOrders = ordersFormatter(data?.storeOrders?.edges ?? []);
  const orders = useRealtimeOrders(formattedOrders);

  return (
    <OrdersContext.Provider
      value={{
        ...orders,
        paginationInfo: {
          totalPages: Math.ceil(totalItems / perPage),
        },
        data,
        loading: !data,
        error,
        formattedOrders: Object.values(orders.ordersDict),
        refetch: () => refetch({ requestPolicy: 'network-only' }),
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export { useOrders, OrdersProvider, orderOrganize };
