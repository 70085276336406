import { gql } from '@urql/core';
import { useQuery } from 'urql';

import { OrderTypeConnection as OrderConnection, RootQueryStoreOrdersArgs } from 'store/types';

const ORDER_HISTORY = gql`
  query OrderHistory(
    $location_Id: Float
    $orderReservation_SelectedDate: Date
    $statuses: [String]
  ) {
    storeOrders(
      location_Id: $location_Id
      orderReservation_SelectedDate: $orderReservation_SelectedDate
      statuses: $statuses
    ) {
      edges {
        node {
          id
          orderNumber
          pk
          created
          status
          paymentMode
          coupon {
            edges {
              node {
                id
                amount
              }
            }
          }
          taxes {
            amount
          }
          deliveryFee {
            amount
          }
          deliveryZone {
            pk
            id
          }
          totalPrice {
            amount
          }
          tip {
            amount
          }
          taxes {
            amount
          }
          platformFee {
            amount
          }
          location {
            id
          }
          subtotal {
            amount
          }
          totalPrice {
            amount
          }
          user {
            email
            firstName
            lastName
            username
          }
          guest {
            email
            firstName
            lastName
          }
          serviceType {
            id
            pk
            service
          }
          menu {
            id
            pk
            name
            displayName
          }
          orderReservation {
            id
            pk
            selectedDate
            selectedTime
          }
          cart {
            edges {
              node {
                id
                pk
              }
            }
          }
        }
      }
    }
  }
`;

function useOrdersQuery(variables: RootQueryStoreOrdersArgs, pause?: boolean) {
  return useQuery<{ storeOrders: OrderConnection }, RootQueryStoreOrdersArgs>({
    query: ORDER_HISTORY,
    variables: {
      ...variables,
      statuses: ['Unseen', 'Confirmed', 'Ready', 'En_route'],
    },
    pause,
    requestPolicy: 'network-only',
  });
}

export default useOrdersQuery;
