import { DateTime } from 'luxon';

import {
  FormattedOrder,
  OrderShippingMode,
  OrderStatus,
  PaymentMethod,
  PaymentMethodLabel,
} from 'store/location/orders/types';
import { Maybe, OrderType as Order, OrderFragment, OrderTypeEdge as OrderEdge } from 'store/types';

export const orderFormatter = (order: Order | OrderFragment): FormattedOrder => {
  const service = order.serviceType?.service?.toLowerCase();

  const date = DateTime.fromFormat(
    `${order.orderReservation?.selectedDate} ${order.orderReservation?.selectedTime}`,
    'yyyy-MM-dd hh:mm:ss',
  );

  const currentUser = order.user ?? order.guest;

  return {
    id: Number(order.pk),
    orderedOn: order.created,
    // dueTime: date.toISO(),
    dueDate: order.orderReservation?.selectedDate!,
    dueTime: order.orderReservation?.selectedTime!,
    dueDatetime: date.toISO(),
    name: currentUser
      ? [currentUser.firstName, currentUser.lastName].filter(a => !!a).join(' ')
      : 'Unknown user',
    email: (order.user?.email || order.guest?.email) ?? 'Email not informed',
    menu: order.menu?.displayName!,
    shipping: service === 'pickup' ? OrderShippingMode.PickUp : OrderShippingMode.Delivery,
    status: order.status as OrderStatus,
    total: order.totalPrice?.amount ?? 0,
    orderNumber: order.orderNumber ?? 'N/A',
    primaryPhone:
      (order.user?.deliveryAddress?.primaryPhone || order.guest?.deliveryAddress?.primaryPhone) ??
      'Unknown phone',
    paymentMethod: order.paymentMode
      ? PaymentMethodLabel[order.paymentMode as PaymentMethod]
      : 'no payment detected',
    rawOrder: order,
  };
};

export const ordersFormatter = (edges: Maybe<OrderEdge>[] = []): FormattedOrder[] =>
  edges.map(edge => orderFormatter(edge!.node!));
