import { ColoredOption } from 'lib/components/forms/select/SelectColored';
import { OrderType as Order, OrderFragment } from 'store/types';

/** An enumeration. */
export enum OrderStatus {
  /** Unseen */
  Unseen = 'Unseen',
  /** Confirmed */
  Confirmed = 'Confirmed',
  /** Ready */
  Ready = 'Ready',
  /** En route */
  EnRoute = 'En_route',
  /** Complete */
  Complete = 'Complete',
  /** Customer cancelled */
  CustomerCancelled = 'Customer_cancelled',
  /** Vendor cancelled */
  VendorCancelled = 'Vendor_cancelled',
}

export interface FormattedOrder {
  id: number;
  name: string;
  email: string;
  menu: string;
  dueTime: string;
  dueDate: string;
  dueDatetime: string;
  orderedOn: string;
  shipping: OrderShippingMode;
  status: OrderStatus;
  total: number;
  orderNumber: string;
  primaryPhone: string;
  paymentMethod: string;
  rawOrder: Order | OrderFragment;
}

export enum OrderShippingMode {
  PickUp,
  Delivery,
}

export const statusIconsColor = {
  [OrderStatus.Complete]: 'text-neutral-50',
  [OrderStatus.Confirmed]: 'text-green-100',
  [OrderStatus.CustomerCancelled]: 'text-red-50',
  [OrderStatus.VendorCancelled]: 'text-red-50',
  [OrderStatus.EnRoute]: 'text-purple-100',
  [OrderStatus.Ready]: 'text-blue-100',
  [OrderStatus.Unseen]: 'text-yellow-100',
};

export const orderBgColor = {
  [OrderStatus.Complete]: 'bg-neutral-600',
  [OrderStatus.Confirmed]: 'bg-green-500',
  [OrderStatus.CustomerCancelled]: 'bg-red-500',
  [OrderStatus.VendorCancelled]: 'bg-red-500',
  [OrderStatus.EnRoute]: 'bg-purple-600',
  [OrderStatus.Ready]: 'bg-blue-500',
  [OrderStatus.Unseen]: 'bg-yellow-500',
};

export const orderBgColorLight = {
  [OrderStatus.Complete]: 'bg-neutral-200',
  [OrderStatus.Confirmed]: 'bg-green-100',
  [OrderStatus.CustomerCancelled]: 'bg-red-50',
  [OrderStatus.VendorCancelled]: 'bg-red-50',
  [OrderStatus.EnRoute]: 'bg-purple-200',
  [OrderStatus.Ready]: 'bg-blue-100',
  [OrderStatus.Unseen]: 'bg-yellow-100',
};

export const statuses: ColoredOption[] = [
  { value: OrderStatus.Unseen, label: 'Unseen', color: 'yellow' },
  { value: OrderStatus.Confirmed, label: 'Confirmed', color: 'green' },
  { value: OrderStatus.Ready, label: 'Ready', color: 'blue' },
  { value: OrderStatus.EnRoute, label: 'On Route', color: 'purple' },
  { value: OrderStatus.Complete, label: 'Complete', color: 'neutral' },
  { value: OrderStatus.VendorCancelled, label: 'Cancelled', color: 'red' },
];

export enum PaymentMethod {
  Stripe = '1',
  CashOnDelivery = '2',
  PayInStore = '3',
  Test = '4',
}

export const PaymentMethodLabel = {
  [PaymentMethod.Stripe]: 'Stripe',
  [PaymentMethod.CashOnDelivery]: 'Cash on Delivery',
  [PaymentMethod.PayInStore]: 'Pay in Store',
  [PaymentMethod.Test]: 'Payment Test',
};
