import { gql, useQuery } from 'urql';

import { LocationType as Location, RootQueryLocationArgs } from 'store/types';

const GET_LOCATION = gql`
  query getLocation($id: Int!) {
    location(id: $id) {
      id
      name
      country
      city
      state
      street
      addressUnit
      zipCode
      email
      phoneNumber
      language
      currency
      timeFormat
      timezone
      unit
      payInStore
      payOnDelivery
      stripePayments
      testPayment
      enablePayInStore
      enableStripePayment
      enablePayOnDelivery
      stripeOnboardingCompleted
      notificationSound
    }
  }
`;

function useLocation(id: string) {
  return useQuery<
    {
      location: Location;
    },
    RootQueryLocationArgs
  >({
    query: GET_LOCATION,
    pause: !id,
    variables: { id: Number(id) },
  });
}

export default useLocation;
