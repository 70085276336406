import { UseQueryResponse, gql, useQuery } from 'urql';

import { UserNode } from 'store/types';

export const GET_USER = gql`
  query getUser {
    me {
      id
      username
      firstName
      lastName
      email
      owners {
        id
        name
      }
    }
  }
`;

export type UseCurrentUserResponse = UseQueryResponse<{ me: UserNode }>;

function useCurrentUser(): UseCurrentUserResponse {
  return useQuery({ query: GET_USER, requestPolicy: 'cache-first' });
}

export default useCurrentUser;
