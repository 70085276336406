import { OrderType as Order, OrderFragment, VendorUpdateOrderMutationInput } from 'store/types';

function getOrderFields(
  order: Order | OrderFragment,
  input: Partial<VendorUpdateOrderMutationInput>,
): VendorUpdateOrderMutationInput {
  return {
    id: Number(order.pk!),
    status: order.status,
    cart: order.cart.edges.map(edge => Number(edge!.node!.pk)),
    location: order.location!.id,
    orderReservation: order.orderReservation?.pk ?? null,
    menu: order.menu?.pk ?? null,
    serviceType: order.serviceType?.pk,
    note: order.note ?? '',
    tip: order.tip?.amount,
    taxes: order.taxes?.amount,
    subtotal: order.subtotal?.amount,
    totalPrice: order.totalPrice?.amount,
    platformFee: order.platformFee?.amount,
    deliveryFee: order.deliveryFee?.amount,
    deliveryZone: order.deliveryZone?.pk,
    paymentMode: order.paymentMode,
    ...input,
  };
}

export default getOrderFields;
