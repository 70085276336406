import 'focus-visible';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { NextRouter, useRouter } from 'next/router';
import React, { ReactElement, ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'urql';

import { OrdersProvider } from 'components/location/orders/orderBorder/shared/useOrders';
import client from 'store';
import useCurrentUser, { UseCurrentUserResponse } from 'store/auth/useCurrentUser';

import '../styles/app.css';


type WithCurrentUserProps = {
  children: (userQuery: UseCurrentUserResponse) => React.ReactNode;
};

function WithCurrentUser({ children }: WithCurrentUserProps) {
  const query = useCurrentUser();

  return <>{children(query)}</>;
}

type Props = AppProps & {
  Component: NextPage & {
    getLayout?: (
      page: ReactElement,
      router?: NextRouter,
      userQuery?: UseCurrentUserResponse,
    ) => ReactNode;
  };
};

function MyApp({ Component, pageProps }: Props) {
  const router = useRouter();
  const getLayout = Component.getLayout || (page => page);

  return (
    <Provider value={client}>
      <OrdersProvider>
        <Toaster />
        <WithCurrentUser>
          {userQuery => (
            <div className="flex h-full flex-col">
              {getLayout(<Component {...pageProps} />, router, userQuery)}
            </div>
          )}
        </WithCurrentUser>
      </OrdersProvider>
    </Provider>
  );
}

export default MyApp;
